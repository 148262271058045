<template>
	<div class="notice-wrap">
		<div class="page_title">首页 &gt; 采购信息 &gt; {{purchaseInfo.purchase_name}}</div>
		
		<div class="purchase_title">
			<div class="purchase_name" @click="toDetail(purchaseInfo.id)">{{purchaseInfo.purchase_name}}</div>
			<div>发布日期：{{purchaseInfo.create_date}}</div>
		</div>

		<table class="purchase_info" cellspacing="0" cellpadding="10">
			<tr>
				<td class="item_name" width="170">采购物品名称</td>
				<td class="item_value">{{purchaseInfo.purchase_name}}</td>
			</tr>
			<tr>
				<td class="item_name">联合国组织机构</td>
				<td class="item_value">{{purchaseInfo.institution}}</td>
			</tr>
			<tr>
				<td class="item_name">参考编号</td>
				<td class="item_value">{{purchaseInfo.sno}}</td>
			</tr>
			<tr>
				<td class="item_name">受惠国</td>
				<td class="item_value">{{purchaseInfo.country}}</td>
			</tr>
			<tr>
				<td class="item_name">通知类型</td>
				<td class="item_value">{{purchaseInfo.type}}</td>
			</tr>
			<tr>
				<td class="item_name">发布时间</td>
				<td class="item_value">{{purchaseInfo.create_date}}</td>
			</tr>
			<tr>
				<td class="item_name">截止时间</td>
				<td class="item_value">{{purchaseInfo.end_date}}</td>
			</tr>
			<tr>
				<td class="item_name">招标说明</td>
				<td class="item_value">{{purchaseInfo.desc}}</td>
			</tr>
			<tr>
				<td class="item_name">规格/型号属性</td>
				<td class="item_value">{{purchaseInfo.spec}}</td>
			</tr>
			<tr>
				<td class="item_name">其他备注</td>
				<td class="item_value">
					<div class="item_memo">
					{{purchaseInfo.memo}}
					</div>
				</td>
			</tr>
		</table>
	</div>
</template>

<script>
import http from "../../utils/http"

/**
 * 商品列表
 * @param {object} params
 */
export function getPurchaseDetail(params) {
    return http({
        url: "/api/purchase/detail",
        data: params
    })
}

export default {
  name: "list",
  data: () => {
    return {
		purchaseId:0,
		purchaseInfo:[],
		total: 0,
		loading:true
	};
  },
  created() {
	  this.purchaseId = this.$route.params.pathMatch;
	  this.getPurchaseInfo();
  },
  methods: {
	  getPurchaseInfo(){
		  const params = {
		      id:this.purchaseId
		  }
		  getPurchaseDetail(params || {})
		  .then(res => {
		  		  this.purchaseInfo = res.data
		  		  this.loading = false
		  })
		  .catch(err => {
		  		  this.loading = false
		  })
	  }
  }
};
</script>
<style lang="scss" scoped>
.notice-wrap {
	margin-top:2px;
	padding-bottom:30px;
	background: #ffffff;
}
.page_title{
	width:100%;
	line-height:60px;
	padding-left:10px;
}
.purchase_title{
	background:#F2F2F2;
	padding:30px 20px 30px 20px;
	box-sizing: border-box;
	border-top:1px solid #E5E5E5;
	border-bottom:1px solid #E5E5E5;
}
.purchase_name{
	font-size:18px;
	font-weight: bold;
}

.purchase_info{
	margin-top:40px;
	width:100%;
	height:34px;
	box-sizing: border-box;
	border-top:1px solid #E5E5E5;
	border-left:1px solid #E5E5E5;
	border-right:1px solid #E5E5E5;
}
.purchase_info td.item_name{
	text-align:left;
	padding:10px 0 10px 20px;
	border-bottom:1px solid #E5E5E5;
	border-right:1px solid #E5E5E5;
	background-color: #F9F9F9;
}
.purchase_info td.item_value{
	text-align:left;
	padding-left:20px;
	border-bottom:1px solid #E5E5E5;
}
.purchase_info .item_memo{
	padding-top:20px;
	padding-bottom:20px;
}
</style>
